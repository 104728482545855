<template>
    <div :key="selectedCat.id" id="content" class="library" :class="[$mq]">
        <div class="header" :class="[{ fullHeader: displayInfo }, { 'full-screen': isFullScreen() }]">
            <!-- ON A CATEGORY -->
            <div class="header-library" v-if="!file" :class="{ right: !selectedCat }">
                <div v-if="selectedCat" class="back" @click="changeCategory(selectedCat.parent_id ? selectedCat.parent_id : false)"></div>
                <div class="name" v-if="selectedCat">{{ selectedCat.name }}</div>
                <div class="search-container" v-if="(!file && $mq != 'portrait') || ($mq == 'portrait' && !selectedCat)">
                    <input ref="searchBarLibrary" class="search" type="text" @input="searchBarInput($event)" :value="search" />
                    <div v-if="search != ''" class="close" @click="cleanSearch()"></div>
                </div>
                <div class="option-btn" @click="setListPreference()"></div>
            </div>
            <!-- ON DETAIL OF A RESOURCE -->
            <div v-if="file" class="back file detail library-file-top-navbar" :class="{ headerDetail: displayInfo }" @click="handleReset($event)">
                <div class="meta">
                    <div class="name" :class="{ fullDesc: displayInfo }" @click="showInfo" :title="file.name">
                        {{ file.name }}
                    </div>
                    <div class="updated" v-if="displayInfo">
                        <div class="update">
                            {{ $t('library.last_update') }} ‎<span class="date"> {{ (file.upload_date * 1000) | moment('DD/MM/YYYY') }}</span>
                        </div>
                        <div class="highlight" v-if="file.highlight"></div>
                    </div>
                </div>
                <button class="btn more" @click.stop="toggleOptions($event.target)">‎</button>
                <div v-if="file.download === 1" class="more-options">
                    <button class="btn download" @click.stop="download()">
                        <!-- {{ $t('menu_aux.action.download') }} -->
                    </button>
                </div>
            </div>
        </div>

        <template v-if="$route.params.id">
            <detail v-if="file" :file="file"></detail>
        </template>
        <template>
            <div class="content hide-scrollbar" :class="[{ searching: true }, { 'hide-scrollbar': ['portrait'].includes($mq) }]">
                <template v-if="!file && search.length < 3 && checkConnetion">
                    <div class="subcategories slideRight" v-if="Object.values(categoriesFiltered).length > 0">
                        <template v-for="item in categoriesFiltered">
                            <LibraryFolder :key="item.id" :item="item" @openFolder="changeCategory(item.id)"></LibraryFolder>
                        </template>
                    </div>
                    <div class="separator"></div>
                </template>
                <transition-group v-if="files.length > 0" @before-leave="beforeLeave" class="files slideRight" name="list" :class="{ list: listPreference === 'list' }" tag="div">
                    <FileItem v-for="item in files" :key="item.id" :file="item" :type="listPreference" @openFile="changeCategory(item.id)"></FileItem>
                </transition-group>

                <div v-else-if="!checkConnetion" class="noConnectionBox">
                    <ConnectionBox class="noConnection" gType="libraryConnection" @reload="load"></ConnectionBox>
                </div>

                <EmptyPage v-else-if="(checkConnetion && files.length == 0 && selectedCat && selectedCat.files.length == 0 && Object.values(categoriesFiltered).length === 0) || categories.length == 0" :title="$t('empty_pages.library_title')" :description="$t('empty_pages.library_description')"></EmptyPage>
            </div>
        </template>
    </div>
</template>

<script>
import EmptyPage from '../components/layout/EmptyPage.vue'
import i18n from '@/i18n'
import detail from '@/components/domain/library/detail'
import column from '@/components/domain/library/column'
import FileItem from '@/components/domain/library/FileItem'
import LibraryFolder from '@/components/domain/library/folder'
import ConnectionBox from '@/components/domain/ui/Box'

export default {
    name: 'Library',
    components: { detail, column, FileItem, EmptyPage, LibraryFolder, ConnectionBox },
    data() {
        return {
            displayInfo: false,
            selectedCat: false,
            showHighlightCat: true,
            loading: true,
            search: '',
            timerSearch: false,
            interval: null,
            idNotification: false,
            num_file: 0,
            num_files: 1,
            listPreference: 'column'
        }
    },
    computed: {
        checkConnetion() {
            return this.$store.getters['getConnState']
        },
        files() {
            if (!this.loading) {
                if (this.search != '' && this.search.length >= 3) {
                    return this.$store.getters['library/getFilesSearched'](this.search)
                } else if (this.selectedCat) {
                    var files = Object.values(this.$store.getters['library/getFiles'](this.selectedCat.id))
                    return files.sort((a, b) => a.name.localeCompare(b.name))
                } else {
                    var files = Object.values(this.$store.getters['library/getHighlightFiles'])
                    this.showHighlightCat = true

                    return files.sort((a, b) => a.name.localeCompare(b.name))
                }
            }
            return false
        },
        file() {
            if (this.$route.params.id) {
                return this.$store.getters['library/getFile'](this.$route.params.id)
            }
            return false
        },
        categories() {
            var items = Object.values(this.$store.getters['library/getCategories'])
            items.sort((a, b) => a.name.localeCompare(b.name))
            return items
        },

        categoriesFiltered() {
            if (this.selectedCat) {
                return Object.values(this.selectedCat.subcategories).sort((a, b) => {
                    return a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })
                })
            } else {
                return this.categories
                    .filter((item) => item.parent_id == 0)
                    .sort((a, b) => {
                        return a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })
                    })
            }
        },
        allCategories() {
            return this.$store.getters['library/getCategories']
        }
    },
    methods: {
        isFullScreen() {
            return this.$store.getters['library/getFullScreen']
        },
        showInfo() {
            // toggle
            this.displayInfo = !this.displayInfo
        },
        beforeLeave(el) {
            const { marginLeft, marginTop, width, height } = window.getComputedStyle(el)
            el.style.left = `${el.offsetLeft - parseFloat(marginLeft, 50)}px`
            el.style.top = `${el.offsetTop - parseFloat(marginTop, 50)}px`
            el.style.width = width
            el.style.height = height
        },
        setListPreference() {
            this.listPreference = this.listPreference === 'column' ? 'list' : 'column'
            localStorage.setItem('listPreference', this.listPreference)
        },
        downloadIni(files) {
            var self = this
            log('downloadIni...', files)
            if (files.length > 0) {
                var file = files.pop()

                log('Downloading...', file)
                if (!self.$store.getters.getIsApp) {
                    var a = document.createElement('a')
                    a.setAttribute('href', file)
                    a.setAttribute('download', '')
                    a.setAttribute('target', '_blank')
                    a.click()

                    if (files.length == 0) {
                        clearInterval(self.interval)
                    }
                } else {
                    clearInterval(self.interval)
                    var fileName = file.replace(/^.*[\\\/]/, '')
                    var fileExtension = fileName.split('.').pop()

                    var fileTransfer = new FileTransfer()
                    fileTransfer.onprogress = function (progressEvent) {
                        if (progressEvent.lengthComputable) {
                            // cordova.plugins.notification.local.update({
                            //     id: self.idNotification,
                            //     title: self.$t('library.download_files_app', { f1: self.num_file + 1, f2: self.num_files }),
                            //     progressBar: { value: self.num_file + (progressEvent.loaded / progressEvent.total) * 100 }
                            // })
                        }
                    }

                    fileTransfer.download(
                        file,
                        cordova.file.externalRootDirectory + 'Download/' + fileName,
                        function (entry) {
                            log('Successful download...')
                            log('download complete: ' + entry.toURL())
                            self.num_file++
                            if (self.num_file == self.num_files) {
                                // const re = /(?:\.([^.]+))?$/
                                // const ext = re.exec(entry.toURL())
                                // const mimeType = getMimeType(ext[1])
                                // console.log(entry.toURL().replace('file://', ''), mimeType, 'cdvfile://localhost/persistent/Download/' + fileName)
                                // cordova.plugins.fileOpener2.open('cdvfile://localhost/persistent/Download/' + fileName, mimeType, {
                                //     error: function (e) {
                                //         console.log('Error status: ' + e.status + ' - Error message: ' + e.message)
                                //     },
                                //     success: function () {
                                //         console.log('file opened successfullDescy')
                                //     }
                                // })
                                // cordova.plugins.notification.local.update({
                                //     id: self.idNotification,
                                //     title: self.$t('library.downloaded', { f1: self.num_file + 1, f2: self.num_files }),
                                //     progressBar: { value: 100 }
                                // })
                                setTimeout(function () {
                                    //TODO WAITING UPDATE PLUGIN TO SUPPORT ANDROID 11
                                    // var actions = []
                                    // const re = /(?:\.([^.]+))?$/
                                    // const ext = re.exec(entry.toURL())
                                    // const mimeType = getMimeType(ext[1])
                                    // console.log(ext[1], mimeType)
                                    // if (mimeType) {
                                    //     actions.push({ id: 'open', title: self.$t('library.open_file') })
                                    //     actions.push({ id: 'hide', title: self.$t('popup.confirm.text_cancel') })
                                    // }
                                    // cordova.plugins.notification.local.update({
                                    //     id: self.idNotification,
                                    //     title: self.$t('library.downloaded'),
                                    //     progressBar: false
                                    //     // actions: actions
                                    // })
                                    // cordova.plugins.notification.local.on('open', function(notification, eopts) {
                                    //     console.log(entry.toURL().replace('file://', ''), mimeType, 'cdvfile://localhost/persistent/Download/' + fileName)
                                    //     cordova.plugins.fileOpener2.open('cdvfile://localhost/persistent/Download/' + fileName, mimeType, {
                                    //         error: function(e) {
                                    //             console.log('Error status: ' + e.status + ' - Error message: ' + e.message)
                                    //         },
                                    //         success: function() {
                                    //             console.log('file opened successfully')
                                    //         }
                                    //     })
                                    // })
                                    // cordova.plugins.notification.local.on('hide', () => {
                                    //     cordova.plugins.notification.local.cancelAll()
                                    // })
                                }, 250)
                            }
                            self.downloadIni(files)
                        },
                        function (error) {
                            log('download error source ' + error.source)
                            log('download error target ' + error.target)
                            log('download error code' + error.code)
                            self.$snackbar.error({ duration: 5000, closeable: true, message: self.$t('library.download_error') })
                        },
                        null, // or, pass false
                        {}
                    )
                }
            } else {
                log(self.$t('library.downloaded'))
                self.$snackbar.success({ duration: 5000, closeable: true, message: self.$t('library.downloaded') })
            }
        },

        download() {
            var files = this.file.file.split('|')
            this.num_files = files.length
            this.num_file = 0

            if (this.$store.getters.getIsApp) {
                console.log(this.$t('library.download_files_app', { f1: 1, f2: this.num_files }))
                this.idNotification = moment().valueOf()
                // cordova.plugins.notification.local.schedule({
                //     id: this.idNotification,
                //     title: this.$t('library.downloading'),
                //     text: this.$t('library.download_files_app', { f1: 1, f2: this.num_files }),
                //     progressBar: { value: 0 }
                // })
            }

            this.$snackbar.success({ duration: 2000, closeable: true, message: this.$t('library.downloading') })
            this.interval = setInterval(this.downloadIni, 1500, files)
        },

        toggleOptions(element) {
            this.displayInfo = !this.displayInfo
            // GET ALL "3 DOTS MORE" BUTTONS
            var divs = document.getElementsByClassName('btn more')
            for (var i = 0; i < divs.length; i++) {
                // GET RELATIVE FROM "3 DOT MORE" BUTTON THE "MORE-OPTIONS" DIV
                var moreOptions = divs[i].parentElement.getElementsByClassName('more-options')[0]
                // ON THE CLICKED BUTTON TOGGLE CLASS
                if (divs[i] === element) {
                    if (moreOptions) moreOptions.classList.toggle('active')
                }
                // ELSE CLOSE THE REST OF "MORE-OPTIONS" DIVS
                else {
                    moreOptions.classList.remove('active')
                }
            }
        },

        searchBarInput(e) {
            const self = this
            if (this.timerSearch) {
                clearTimeout(this.timerSearch)
                this.timerSearch = null
            }
            this.timerSearch = setTimeout(() => {
                self.search = e.target.value
                self.selectedCat = false
            }, 800)
        },
        cleanSearch() {
            this.search = ''
        },
        getStringObject(str, obj) {
            return i18n.t(str, obj)
        },

        load() {
            var self = this
            this.$overlay.loading()
            this.$store.dispatch('library/loadFiles').then(function () {
                self.$bar.show()
                self.loading = false
                if (self.file) {
                    if (!['portrait'].includes(self.$mq)) {
                        // self.$bar.hideAction('back')
                        self.$bar.addAction('back', {
                            callback: function () {
                                self.$router.push('/library')
                            },
                            label: self.$t('menu_aux.action.back')
                        })
                        self.$bar.hideAction('search')
                    }
                } else {
                    self.$bar.hideAction('back')
                    if (!['portrait'].includes(self.$mq)) {
                        self.$bar.addNav(
                            [
                                {
                                    label: self.$t('tools.home_title'),
                                    callback: function () {
                                        self.selectedCat = null
                                        self.$router.push('/library')
                                        self.$bar.addNav([{ label: self.$t('tools.home_title'), callback: function () {} }])
                                    }
                                }
                            ],
                            'breadcrumbs'
                        )
                    }
                }
                self.$overlay.close()
            })
        },
        handleReset(event) {
            const clickedElement = event.target
            if (clickedElement.classList.contains('back') && clickedElement.classList.contains('file') && clickedElement.classList.contains('detail')) {
                this.reset()
            }
        },

        changeCategory(category) {
            console.warn('changeCategory')
            console.warn(category)
            // find recursively the category in the categories array
            if (category) {
                const cat = this.findCategoryById(Object.values(this.allCategories), category)
                this.selectedCat = cat
            } else {
                this.selectedCat = category
            }
            this.search = ''
        },
        findCategoryById(categories, categoryId) {
            for (let id in categories) {
                const category = categories[id]
                if (category.id === categoryId) {
                    return category
                } else if (category.subcategories) {
                    const subcategory = this.findCategoryById(category.subcategories, categoryId)
                    if (subcategory) {
                        return subcategory
                    }
                }
            }
            return null // Devuelve null si no se encuentra la categoría
        },
        reset() {
            this.$bar.reset()
            this.$router.push('/library')
        },
        displaySearch() {
            var self = this
            self.$popup.input({
                title: self.$t('library.alex_search'),
                textSave: self.$t('menu_aux.action.search'),
                textCancel: self.$t('popup.default.text_cancel'),
                messageError: self.$t('library.empty_search_error'),
                inputVal: '',
                callSave: function (pSelf, store) {
                    pSelf.showingError = false
                    pSelf.inputVal = pSelf.inputVal.trim()

                    if (pSelf.inputVal != '' && pSelf.inputVal.length > 0) {
                        // self.changeCategory('')
                        self.search = pSelf.inputVal
                        self.$popup.close()
                    } else {
                        pSelf.showingError = true
                    }
                }
            })
        }
    },
    filters: {
        moment: function (date, format) {
            return date ? moment(date).format(format) : ''
        }
    },
    watch: {},
    created() {
        this.$bar.reset()
        this.load()
        // get list preference from local storage
        this.listPreference = localStorage.getItem('listPreference') ? localStorage.getItem('listPreference') : 'column'
    },
    beforeRouteLeave(to, from, next) {
        next()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#content.library {
    overflow: hidden;
    padding: 8px;

    .header {
        width: 100%;
        height: auto !important;
        // margin-bottom: 12px;
        padding-bottom: 16px;

        &.full-screen {
            z-index: 1;
        }

        .back {
            width: 100%;
            height: 40px;
        }
        .file.detail .meta .name,
        .file.detail .meta .updated {
            margin-left: 55px;
        }

        .file.detail .meta {
            width: calc(100% - 65px);
        }

        .back::before {
            position: fixed;
            width: 40px;
            height: 40px;
            align-self: flex-start;
            border-radius: 4px;
        }
        .btn {
            &.more {
                z-index: $z-overlay-default;
                // margin-bottom: 8px;
            }
        }
        .headerDetail {
            height: auto !important;
        }
        .more-options {
            border-radius: 40px;
            background-clip: padding-box;
            @include align-items();
            display: none;
            align-content: center;
            height: fit-content;
            width: max-content;
            background-color: none;
            position: absolute;
            z-index: $z-overlay-default;
            &.active {
                @include display-flex();
                @include justify-content();
                @include align-items();
                @include flex-direction(column);
                background-color: transparent;
            }

            .btn {
                @include display-flex();
                @include justify-content();
                @include align-items();
                width: 100%;
                height: 40px;
                min-width: 40px;
                position: absolute;
                right: -2px;
                bottom: -4px;
                z-index: $z-overlay-default;
                &.download {
                    @include background($image: img('download_fff.svg'), $size: 25px, $position: center center);
                    margin-right: 12px;
                }
            }
        }

        .name {
            // display: -webkit-box;
            // -webkit-box-orient: vertical;
            // overflow: hidden;
            // -webkit-line-clamp: 2;
            // text-overflow: ellipsis;
            // word-wrap: break-word;
            // white-space: wrap !important;
            // @include font-size(ml);
            // margin-left: 45px;
        }

        .fullDesc {
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
            white-space: wrap !important;
        }
        .header-library {
            width: 100%;
            @include display-flex();
            @include justify-content(flex-start);
            @include align-items(center);

            .back {
                width: auto;
            }
            .ball {
                @include display-inline-flex();
                @include border-radius(100%);
                width: 12px;
                height: 12px;
                min-width: 12px;
                min-height: 12px;
                margin-left: 12px;

                &.highlight {
                    @include background($image: img('star_main.svg'), $size: 18px, $position: center -2px);
                    width: 18px;
                    height: 18px;
                }
            }
            .name {
                @include font-size(ml);
                line-height: 130%;
                margin-left: 45px;
                font-family: $text-bold;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: wrap !important;
                width: calc(100% - 50px);
                max-height: calc(100% - 24px);
                margin-top: 1px;
                padding-left: 10px;
            }
            .file.detail {
                @include display-flex();
                flex-direction: row;
                @include align-items(center);
                justify-content: space-between !important;
                color: $neutro-s80;
                height: auto;

                .meta {
                    @include display-inline-flex();
                    @include flex-direction(column);
                    width: calc(100% -65px);

                    .name,
                    .updated,
                    .updated {
                        padding-left: 55px;

                        .update {
                            @include font-size(s);
                            line-height: 20px;
                            font-family: $text;
                            margin-left: 55px;
                        }
                    }
                    .name {
                        display: block;
                        @include text-ellipsis();
                        font-family: $text-bold;
                        overflow: hidden;
                        text-wrap: balance;
                        color: red !important;
                        font-size: 24px;
                        margin-left: 55px;
                    }

                    .full .updated {
                        @include display-flex();

                        .update {
                            @include display-inline-flex();
                            @include font-size(13px);
                            @include text-ellipsis();
                            color: $neutro-s60;

                            .date {
                                font-family: $text-bold;
                                color: $neutro-s60;
                            }
                        }
                    }
                }

                .btn {
                    @include font-size(16);
                    max-width: 250px;
                    height: 40px;
                    width: 40px;
                    cursor: pointer;
                    &.delete {
                        @include background($image: img('delete_ffffff.svg'), $size: 20px, $position: left 5px center);
                        background-color: $error;
                        color: $neutro-light;
                        padding-left: 30px;
                        height: 55px;
                        &:hover {
                            background-color: $error-dark;
                        }
                    }
                    &.more {
                        @include background($image: img('more_vert_primary.svg'), $size: 20px, $position: center center);
                        background-color: $default-sec-color;
                        // &:hover {
                        // }
                    }
                }
            }

            .option-btn {
                @include background($image: img('list_neutro.svg'), $size: 24px, $position: center center);
                @include border-radius(4px);
                width: 40px;
                height: 40px;
                min-width: 40px;
                min-height: 40px;
                background-color: #fff;
                margin-left: 12px;
                cursor: pointer;

                &:hover {
                    @include bgHover(#fff);
                }
            }
            .search-container {
                width: 40%;
                margin-left: auto;

                input {
                    margin: 0px !important;
                }

                .search {
                    @include display-flex();
                    @include align-items(center);
                    @include background($image: img('search_neutro.svg'), $size: 25px, $position: left 12px center);
                    @include border-radius(4px);
                    @include font-size(ml);
                    height: 40px;
                    width: 100%;
                    background-color: #fff;
                    padding: 0 60px 0 45px;
                    border-bottom: none;
                    font-family: $text;
                    cursor: text;
                }

                .close {
                    @include border-radius(4px);
                    @include background($image: img('close_dark.svg'), $size: 12px, $position: center center);
                    width: 33px;
                    height: 33px;
                    position: absolute;
                    top: 6px;
                    right: 70px;
                    background-color: $neutro-t50;
                    cursor: pointer;
                }
            }
            &.right {
                justify-content: flex-end;

                .option-btn {
                    margin-left: 12px;
                }
            }
        }
    }

    .detailParent {
        height: 60px;
        .full {
            text-wrap: balance;
            color: red;
        }
    }
    .content {
        width: 100% !important;
        min-width: unset !important;
        height: calc(100% - 60px) !important;
        .contain {
            width: 100%;
            height: auto;
            @include display-flex();
            @include flex-direction(column);

            .alex-header {
                width: 100%;
                margin: 0 auto;
                @include display-flex();
                @include align-items();
                @include justify-content();
                @include flex-direction(column);
                margin-bottom: 15px;
                padding: 8px;

                .search-container {
                    position: relative;
                    top: 0px !important;
                    padding: 0px !important;
                }
                .messages-container {
                    @include display-flex();
                    @include align-items();
                    padding-top: 10px;

                    .icon {
                        @include background($image: img('alexhead_06.svg'), $size: 60px, $position: top center);
                        height: 60px;
                        width: 60px;
                        margin-right: 10px;
                        display: inline-block;

                        &.oops {
                            @include background($image: img('alexhead_03.svg'), $size: 60px, $position: top center);
                        }
                        &.search {
                            @include background($image: img('search_neutro.svg'), $size: 25px);
                            right: 10px;

                            &.selected {
                                @include background($image: img('search_light.svg'), $size: 25px);
                            }
                        }
                    }

                    .message {
                        @include font-size(m);
                        @include border-radius(6px);
                        background-color: $info-t40;
                        display: inline-block;
                        width: calc(100% - 60px - 10px);
                        padding: 10px;
                        font-family: $text;
                        color: $main-s70;

                        &.error {
                            background-color: $warning-t40;
                        }
                    }
                }
            }

            .empty {
                @include background($image: img('empty4.svg'), $size: 70px, $position: top center);
                margin-top: 60px;
                padding-top: 90px;
                width: 100%;
            }
        }

        .subcategories {
            display: grid;
            gap: 10px;
            grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
            height: auto;
            grid-template-rows: 55px;

            .separator {
                @include display-flex();
                width: 100%;
                margin-bottom: 20px;
            }
        }

        .files {
            display: grid;
            gap: 10px;
            grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
            height: auto;
            grid-template-rows: 150px;
            padding-top: 8px;

            &.list {
                @media screen and (min-width: 0px) and (max-width: 360px) {
                    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
                }
                grid-template-columns: repeat(auto-fill, minmax(333px, 1fr));
                grid-template-rows: 80px;
            }
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss" scoped>
#fullcontent {
    #content.portrait {
        .library-file-top-navbar {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .content.right {
            width: 100%;
            max-width: 100%;
            float: none !important;
            height: calc(100% - 75px);

            &.searching {
                margin-top: 10px;
                height: calc(100% - 120px - 10px) !important;
            }

            .contain {
                @include display-flex();
                @include flex-wrap(wrap);
                @include flex-direction(row);
                gap: 13px 2%;
                height: auto;

                .document {
                    margin: 0;
                }
            }

            .alex-header {
                width: 100%;
                margin-top: 25px;

                .message {
                    width: 100%;
                    @include font-size(sm);
                }
            }
        }

        .subcategories {
            .subcategory {
                .name {
                    @include font-size(s);
                    -webkit-hyphens: auto;
                    -moz-hyphens: auto;
                    -ms-hyphens: auto;
                    hyphens: auto;
                    word-wrap: break-word;
                }
            }
        }
    }
    .library {
        &.portrait {
            height: calc(100%) !important;
            overflow: hidden !important;
            .content {
                padding: 0 !important;
            }

            .header {
                position: relative;
                height: 45px;
                width: 100%;
                z-index: 10;

                &.full-screen {
                    z-index: 1;
                }
                .header-library {
                    &.right {
                        .search-container {
                            width: 100%;
                            padding: 0px;
                        }
                    }

                    .name {
                        @include font-size(sm);
                        // white-space: nowrap !important;
                        // @include text-ellipsis($line: 1);
                        width: 100%;
                        line-height: 1rem;
                        max-height: 95%;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        // overflow: hidden;
                        // text-overflow: ellipsis;
                    }
                    // .name {
                    //     @include text-ellipsis($line: 1);
                    //     width: 100%;
                    // }
                }
                .meta {
                    width: calc(100% - 50px) !important;
                }
                .btn.more {
                    align-self: flex-start;
                    width: 40px;
                    height: 40px;
                    max-width: 40px;
                    height: 40px;
                }
                .more-options {
                    border-radius: 40px;
                    background-clip: padding-box;
                    @include align-items();
                    display: none;
                    align-content: center;
                    height: fit-content;
                    width: max-content;
                    background-color: none;
                    position: absolute;
                    z-index: 9999999;
                    &.active {
                        @include display-flex();
                        @include justify-content();
                        @include align-items();
                        @include flex-direction(column);
                        background-color: transparent;
                        position: absolute;
                        z-index: 9999999;
                    }

                    .btn {
                        @include display-flex();
                        @include justify-content();
                        @include align-items();
                        width: 100%;
                        height: 40px;
                        min-width: 40px;
                        position: absolute;
                        right: -2px;
                        bottom: -4px;
                        z-index: 99999;
                        &.download {
                            @include background($image: img('download_fff.svg'), $size: 25px, $position: center center);
                            margin-right: -3px;
                        }
                    }
                }
            }
        }
    }
}
</style>
